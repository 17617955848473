import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import axios from "axios";
import VueAxios from "vue-axios";
import { createI18n } from "vue-i18n";
import en from "@/locales/en.json";
import tr from "@/locales/tr.json";
import AllMixins from "@/mixins/mixins";
import PrimeVue from 'primevue/config';
import { maska } from "maska";
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import InputSwitch from 'primevue/inputswitch';
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import TriStateCheckbox from "primevue/tristatecheckbox";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import Datepicker from "@vuepic/vue-datepicker";
import InputNumber from "primevue/inputnumber";
import Toast from 'primevue/toast';
import Message from 'primevue/message';
import ConfirmDialog from 'primevue/confirmdialog';
import Calendar from 'primevue/calendar';
import OverlayPanel from 'primevue/overlaypanel';
import FileUpload from 'primevue/fileupload';
import Listbox from 'primevue/listbox';
import Card from 'primevue/card';
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/lara-light-purple/theme.css';
import 'primevue/resources/primevue.min.css';
import Button from 'primevue/button';
import AddDailyContentModal from "@/views/Modals/AddDailyContentModal.vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import ScrollPanel from 'primevue/scrollpanel';
import Divider from 'primevue/divider';
import Slider from 'primevue/slider';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColorPicker from 'primevue/colorpicker';
import RadioButton from 'primevue/radiobutton';
import AstopiaTable from "../src/components/Tables/AstopiaTable.vue";
import EditModal from "../src/components/Modals/EditModal.vue";
import CreateModal from "../src/components/Modals/CreateModal.vue";
import DeleteModal from "../src/components/Modals/DeleteModal.vue";
import {appAxios} from "./utils/appAxios";


const i18n = createI18n({
  locale: store.state.lang, // set locale
  fallbackLocale: "en", // set fallback locale
  messages: {
    en: en,
    tr: tr
  },
});
// eslint-disable-next-line dot-notation
axios.defaults.headers.common["Authorization"] =
  "bearer " + localStorage.getItem("token");

axios.defaults.headers.common["Accept-Country"] = localStorage.getItem(
  "country"
);

axios.defaults.headers.common["Locale"] = new Date().getTimezoneOffset()

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem("token");
      this.goPage('/welcome');
    }
    if (error.response.status === 403) {
      app.config.globalProperties.$toast.add({severity:'error', summary: 'Error', detail:'You are not authorized to take this action.', life: 3000});
    }

    return Promise.reject(error);
  }
);

Datepicker.props.format.default = "dd-MM-yyyy HH:mm";
const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  .use(VueAxios, axios)
  .use(PrimeVue)
  .use(i18n)
  .use(ToastService)
  .use(ConfirmationService);

app.component('InputSwitch', InputSwitch)
app.component('InputText', InputText)
app.component('Textarea', Textarea)
app.component('MultiSelect', MultiSelect)
app.component('Dropdown', Dropdown)
app.component('Dialog', Dialog)
app.component('InputNumber', InputNumber)
app.component('Toast', Toast)
app.component('Message', Message)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Calendar', Calendar)
app.component('TriStateCheckbox', TriStateCheckbox)
app.component('Datepicker', Datepicker)
app.component('OverlayPanel', OverlayPanel)
app.component('FileUpload', FileUpload)
app.component('Listbox', Listbox)
app.component('Card', Card)
app.component('Button', Button)
app.component('AddDailyContentModal', AddDailyContentModal)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('ToggleButton', ToggleButton)
app.component('ScrollPanel', ScrollPanel)
app.component('Divider', Divider)
app.component('Slider', Slider)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('ColorPicker', ColorPicker)
app.component('RadioButton', RadioButton)
app.component('AstopiaTable', AstopiaTable)
app.component('EditModal', EditModal)
app.component('CreateModal', CreateModal)
app.component('DeleteModal', DeleteModal)
app.config.globalProperties.$appAxios = appAxios

app.directive("maska", maska);
app.directive("tooltip", Tooltip);
app.mixin(AllMixins);
app.mount("#app");
