<template>
  <Dialog
      :header="modalTitle"
      v-model:visible="showModal"
      modal
      :style="{ width: modalWidth }"
      @hide="hideModal">
    <form @submit.prevent="submitForm">
      <div v-for="(field, index) in formFields" :key="index" class="form-group d-flex"
           :class="{'flex-column justify-content-center': field.type !== 'switch', 'flex-row': field.type === 'switch'}">
        <label :for="field.model">{{ field.label }}</label>

        <!-- Text input -->
        <input v-if="field.type === 'text'"
               :id="field.model"
               :placeholder="field.placeholder"
               v-model="formData[field.model]"
               type="text"
               class="form-control w-100" />

        <!-- Number input -->
        <input v-else-if="field.type === 'number'"
               :id="field.model"
               :placeholder="field.placeholder"
               v-model="formData[field.model]"
               type="number"
               class="form-control w-100" />

        <!-- Date input -->
        <input v-else-if="field.type === 'date'"
               :id="field.model"
               :placeholder="field.placeholder"
               v-model="formData[field.model]"
               type="date"
               class="form-control w-100" />

        <!-- Select input -->
        <select v-else-if="field.type === 'select'"
                :id="field.model"
                v-model="formData[field.model]"
                class="form-control w-100">
          <option v-for="option in field.options" :key="option[field.id]" :value="option[field.id]">
            {{ option[field.value] }}
          </option>
        </select>


        <Dropdown v-else-if="field.type === 'drop'"
                  v-model="formData[field.model]"
                  :options="field.options"
                  :optionLabel="field.value"
                  :optionValue="field.id"
                  class="w-100"
        />


        <MultiSelect v-else-if="field.type === 'multi'"
                     class="w-100"
                     :options="field.options"
                     v-model="formData[field.model]"
                     :optionLabel="field.value"
                     :optionValue="field.id"
                     multiple />


        <Dropdown v-else-if="field.type === 'country'"
                  v-model="formData[field.model]"
                  :options="countries"
                  optionLabel="name" optionValue="id"
                  placeholder="Select a Country"
                  filter
                  @change="getCities"
                  class="w-100"
        />


        <Dropdown v-else-if="field.type === 'city'"
                  v-model="formData[field.model]"
                  :options="cities" optionLabel="name" optionValue="id"
                  placeholder="Select a City" @change="getDistricts" filter class="w-100"
        />


        <Dropdown v-else-if="field.type === 'state'"
                  v-model="formData[field.model]" :options="districts" optionLabel="name" optionValue="id"
                  placeholder="Select a District" filter class="w-100"/>


        <!-- Switch input -->
        <input v-else-if="field.type === 'switch'"
               :id="field.model"
               type="checkbox"
               v-model="formData[field.model]"
               class="form-switch ms-2" />

        <!-- Custom hour-minute input -->
        <div v-else-if="field.type === 'hour-minute'" class="hour-minute-input d-flex gap-3">
          <input type="number" v-model="formData[field.hour]" placeholder="Hour" class="form-control"/>
          :
          <input type="number" v-model="formData[field.minute]" placeholder="Minute" class="form-control"/>
        </div>

        <!-- Custom field types can be added here -->



        <div v-else-if="field.type === 'languageInfos'" class="form-group">
          <div class="d-flex gap-2 align-items-center">
            <MultiSelect
                         class="w-100"
                         :options="field.options"
                         v-model="newLanguage.key"
                         :optionLabel="field.value"
                         :optionValue="field.id"
                         multiple />

            <input v-model="newLanguage.isActive" type="checkbox" class="form-switch" /> Active
            <button type="button" class="btn btn-success" @click="addLanguage">+</button>
          </div>

          <div v-for="(langInfo, index) in languageInfos" :key="index" class="d-flex gap-2 mt-2 align-items-center">
            <span>{{ langInfo.key }} - {{ langInfo.isActive ? 'Active' : 'Inactive' }}</span>
            <button type="button" class="btn btn-danger" @click="removeLanguage(index)">-</button>
          </div>
        </div>



        <div v-else-if="field.type === 'chakraContents'" class="form-group">

          <div class="d-flex gap-2 flex-column">
            <label :for="field.model">Contents Type</label>
            <input
                   :id="field.model"
                   :placeholder="'Enter Contents Type'"
                   v-model="newContent.type"
                   type="text"
                   class="form-control w-100" />

            <label :for="field.model">Contents Variant</label>
            <input
                   :id="field.model"
                   :placeholder="'Enter Contents Variant'"
                   v-model="newContent.variant"
                   type="number"
                   class="form-control w-100" />

            <label :for="field.model">Contents Text</label>
            <input
                   :id="field.model"
                   :placeholder="'Enter Contents Text'"
                   v-model="newContent.text"
                   type="text"
                   class="form-control w-100" />
            <label :for="field.model">Contents Language</label>
            <Dropdown
                class="w-100"
                :options="languages"
                v-model="newContent.language"
                optionLabel="languageKey"
                optionValue="languageKey"
                :placeholder="'Enter Contents Language'"
            />
            <label :for="field.model">Contents Image Path</label>
            <input
                   :id="field.model"
                   :placeholder="'Enter Contents Image Path'"
                   v-model="newContent.imagePath"
                   type="text"
                   class="form-control w-100" />

            <button type="button" class="btn btn-success" @click="addChakraContent">+</button>
          </div>

          <div v-for="(langInfo, index) in chakraContents" :key="index" class="d-flex gap-2 mt-2 align-items-center">
            <span>{{ langInfo }}</span>
            <button type="button" class="btn btn-danger" @click="removeChakraContent(index)">-</button>
          </div>
        </div>



      </div>

      <!-- Submit button -->
      <button type="submit" class="btn btn-primary">Save</button>
    </form>
  </Dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    modalTitle: String,
    modalWidth: {
      type: String,
      default: '50%'
    },
    formFields: {
      type: Array,
      required: true
    },
    method: {
      type: String,
      default: 'post'
    }
  },
  data() {
    return {
      formData: {},
      countries: [],
      cities: [],
      districts: [],
      languages: [],
      newLanguage: {
        key: '',
        isActive: false
      },
      languageInfos: [],
      newContent: {
        id: null,
        type: '',
        variant: '',
        text: '',
        language: '',
        imagePath: ''
      },
      chakraContents: [],
    };
  },
  watch: {
    formFields: {
      immediate: true,
      handler(fields) {
        fields.forEach(field => {
          if (field.type === 'hour-minute') {
            this.formData[field.hour] = 0;
            this.formData[field.minute] = 0;
          } else {
            this.formData[field.model] = field.type === 'switch' ? false : '';
          }
        });
      }
    },
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      },
    },
  },
  mounted() {
    this.$appAxios.get("global/countries")
        .then(resp => {
          this.countries = resp.data.response
        })
        .catch(err => {
          console.log(err)
        });
    this.$appAxios.get("admin/app-settings/language/list?test=test&offset=0&limit=10000")
        .then(resp => {
          this.languages = resp.data.languages
        })
        .catch(err => {
          console.log(err)
        });
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    async submitForm() {
      this.formData.languageInfos = this.languageInfos
      this.formData.chakraContents = this.chakraContents
      this.$emit('saveEndpoint', this.formData)
    },
    getCities(event) {
      var countryId = event.value;
      this.$appAxios.get("global/cities/" + countryId)
          .then(resp => {
            this.cities = resp.data.response;
          })
          .catch(err => {
            console.log(err)
          });
    },
    getDistricts(event) {
      var cityId = event.value;
      this.$appAxios.get("global/districts/" + cityId)
          .then(resp => {
            this.districts = resp.data.response;
          })
          .catch(err => {
            console.log(err)
          });
    },
    addLanguage() {
      if (this.newLanguage.key.length > 0) {
        this.newLanguage.key.forEach((languageKey) => {
          const existingLanguage = this.languageInfos.find(lang => lang.key === languageKey);
          if (!existingLanguage) {
            this.languageInfos.push({
              key: languageKey,
              isActive: this.newLanguage.isActive
            });
          }
        });
        this.newLanguage.key = [];
        this.newLanguage.isActive = false;
      }
    },
    removeLanguage(index) {
      this.languageInfos.splice(index, 1);
    },
    addChakraContent() {
      this.chakraContents.push({ ...this.newContent });
      this.newContent = { id: null,type: '', variant: '', text: '', language: '', imagePath: '' };
    },
    removeChakraContent(index) {
      this.chakraContents.splice(index, 1);
    },
  }
};
</script>
