<template>
  <select
      v-model="$i18n.locale"
      :change="changeLang()"
      name="localSwitcher"
      class="form-select switcher"
  >
    <option
        v-for="locale in $i18n.availableLocales"
        :key="`locale-${locale}`"
        :value="locale"
    >
      {{ languageText(locale) }}
    </option>
  </select>
</template>

<script>
export default {
  name: "LocalSwitcher",
  data: function () {
    return {
      lang: this.$store.state.lang,
    };
  },
  methods: {
    languageText(param) {
      let parameter;
      switch (param) {
        case "tr":
          if (this.lang === "tr") {
            parameter = "TR";
          } else if (this.lang === "en") {
            parameter = "TR";
          }
          break;
        case "en":
          if (this.lang === "tr") {
            parameter = "EN";
          } else if (this.lang === "en") {
            parameter = "EN";
          }
          break;
      }
      return parameter;
    },
    changeLang() {
      if (this.$store.state.lang !== "tr" && this.$store.state.lang !== "en") {
        let language = window.navigator.userLanguage || window.navigator.language;
        if (language.toLowerCase().includes("tr")) {
          this.$store.commit("setLang", "tr");
        } else if (language.toLowerCase().includes("en")) {
          this.$store.commit("setLang", "en");
        } else {
          this.$store.commit("setLang", "en");
        }
      }else{
        this.$store.commit("setLang", this.$i18n.locale)
      }
    },
  },
  created() {
    if (this.$store.state.lang !== "tr" && this.$store.state.lang !== "en") {
      let language = window.navigator.userLanguage || window.navigator.language;
      if (language.toLowerCase().includes("tr")) {
        this.$store.commit("setLang", "tr");
      } else if (language.toLowerCase().includes("en")) {
        this.$store.commit("setLang", "en");
      } else {
        this.$store.commit("setLang", "en");
      }
    }
  },
};
</script>
<style scoped>
.switcher {
  border: none !important;
  font-size: 12px;
}

.switcher:focus {
  border: none !important;
}
</style>